<template>
  <div class="preloader">
    <div class="loader">
      <div class="father">
        <div class="one"></div>
      </div>
      <div class="two"></div>
      <div class="three"></div>
      <div class="mother">
        <div class="num-for"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader"
}
</script>

<style lang="scss">
@import "../scss/base/loader";
</style>