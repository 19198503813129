<template>
  <div class="page-content">
    <!-- Search Section -->
    <section id="search-section" class="page-section text-white mb-0">
      <div class="container align-items-center">

        <!-- Search Heading -->
        <div class="text-center mt-3 mb-3">
          <h1 class="page-section-heading text-uppercase text-secondary pb-lg-3">Zoeken</h1>
        </div>

        <!-- Search Section Content -->
        <div class="row">
          <div class="col-lg-8 mx-auto">
            <p class="text-primary">Op de website worden standaard alle beschrijvingen (zo mogelijk voorzien van een
              afbeelding) van items uit de Erfgoedcollectie getoond: boeken, tijdschrift(artikel)en, foto’s, objecten,
              redes, dossiers, AV-materiaal etc.
            </p>
            <p class="text-primary">Verfijnen van deze grote set is mogelijk door één of meerdere facetten in de
              linkerkolom aan te vinken. Deze facetten kunnen alfabetisch (met de knop A-Z) en/of numeriek (met de knop
              0-9) gesorteerd worden. De geactiveerde facetten verschijnen bovenaan de pagina. De selectie wordt
              ongedaan gemaakt door het facet uit te vinken.
            </p>
            <p class="text-primary">Door het invullen van één of meerdere termen in de zoekbalk kan specifiek gezocht
              worden in de collectie. Meerdere zoektermen worden standaard gecombineerd met de operator “EN”. Zoeken op
              bijvoorbeeld de termen rede Lubbers levert items op met in één van de velden zowel het woord rede als het
              woord Lubbers. Door zoektermen tussen aanhalingstekens te zetten, wordt gezocht op een exacte
              woordcombinatie, bijvoorbeeld "Portretten rectores magnifici". Daarnaast kan in één zoekopdracht op
              verschillende woordcombinaties gezocht worden door het truncatie-symbool * te gebruiken. Zo levert zoeken
              op bestuur* niet alleen resultaten op met bestuur in één van de velden, maar bijvoorbeeld ook met Bestuur
              en Beheer, bestuurskunde, bestuursvergaderingen etc.
            </p>
            <p class="text-primary">Door alleen een * als zoekterm in te vullen, worden alle resultaten getoond.
            </p>
            <p class="text-primary">Gebruik <span class="font-weight-bold font-italic">Uitgebreid zoeken</span>
              voor specifieke zoekacties (bijvoorbeeld zoeken op vervaardiger, titel, omschrijving etc.)
              of voor het combineren met andere operatoren (“NIET”, “OF”).
            </p>
            <ul class="text-primary">
              <li>Bij zoeken op “<span class="font-italic">EN</span>” wordt gezocht op resultaten die
                beide zoekopdrachten bevatten.
                Bijvoorbeeld: <span class="font-italic">rede</span> EN <span class="font-italic">Lubbers</span>
                geeft enkel die resultaten die zowel rede als Lubbers bevatten.
              </li>
              <li>Zoeken met “OF” geeft alle resultaten die een van beide zoekwoorden bevatten. Bijvoorbeeld:
                <span
                    class="font-italic">rede
                </span> OF <span class="font-italic">Lubbers</span> geeft alle resultaten die <span class="font-italic">rede</span>
                of <span class="font-italic">Lubbers</span> bevatten.
              </li>
              <li>Gebruik van de operator “NIET” geeft enkel resultaten die wel de eerste zoekopdracht, maar niet
                de
                tweede zoekopdracht bevatten. Bijvoorbeeld: <span class="font-italic">rede</span> NIET <span
                    class="font-italic">Lubbers</span> geeft alle resultaten die <span
                    class="font-italic">Lubbers</span>
                bevatten, maar niet het woord <span class="font-italic">Lubbers</span>.
              </li>
            </ul>
            <p class="text-primary">Klikken op het “plusteken (+)” voegt een extra zoekveld toe.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Presentation Section -->
    <section id="presentation-section" class="page-section text-white">
      <div class="container align-items-center">

        <!-- Presentation Section Heading -->
        <div class="text-center mb-3 mt-3 pt-lg-5">
          <h1 class="page-section-heading text-uppercase text-secondary pb-lg-3">Presentatie</h1>
        </div>

        <!-- Presentation Section Content -->
        <div class="row">
          <div class="col-lg-8 mx-auto">
            <p class="text-primary">De gevonden items kunnen op twee manieren worden getoond: als lijst of als
              gallery. Wisselen tussen beide weergaves kan met de knoppen rechtsboven de gepresenteerde
              resultaten:
              <svg data-view-type="list" class="bi bi-view-list" width="1em" height="1em" viewBox="0 0 16 16"
                   fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path data-view-type="list" fill-rule="evenodd"
                      d="M3 4.5h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1H3zM1 2a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 2zm0 12a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 14z"></path>
              </svg>
              <svg data-view-type="grid" class="bi bi-grid-3x3-gap" width="1em" height="1em" viewBox="0 0 16 16"
                   fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path data-view-type="grid" fill-rule="evenodd"
                      d="M4 2H2v2h2V2zm1 12v-2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm0-5V7a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm0-5V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm5 10v-2a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm0-5V7a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm0-5V2a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zM9 2H7v2h2V2zm5 0h-2v2h2V2zM4 7H2v2h2V7zm5 0H7v2h2V7zm5 0h-2v2h2V7zM4 12H2v2h2v-2zm5 0H7v2h2v-2zm5 0h-2v2h2v-2zM12 1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1h-2zm-1 6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zm1 4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1h-2z"></path>
              </svg>
            </p>
            <p class="text-primary">Door te klikken op een zoekresultaat verschijnt de volledige beschrijving van
              het betreffende object.
            </p>
            <p class="text-primary">Met de knoppen <a id="static-next" class="btn diw-button item-nav help-page">Volgende</a>
              en <a id="static-previous" class="btn diw-button item-nav help-page">Vorige</a> kan gebladerd
              worden binnen de gevonden resultaten.
            </p>
            <p class="text-primary">Tekstgedeeltes in <span class="text-secondary"><u>blauw</u></span> zijn
              clickable. Hierdoor wordt automatisch een nieuwe
              zoekactie gestart met desbetreffende zoekterm.
            </p>
            <p class="text-primary">Eventuele aanvullende aanwezigheid en de beschikbaarheid van boeken en seriële werken dient apart nagezocht te worden
              in de online catalogus van de Universiteitsbibliotheek Tilburg. Zie hiervoor:
              <a href="https://tilburguniversity.on.worldcat.org/discovery" target="_blank"
                 class="text-secondary">Catalogus
                Universiteitsbibliotheek</a>.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Help close button -->
    <CloseButton/>

  </div>
</template>

<script>
import CloseButton from '@/components/CloseButton'

export default {
  name: 'Help',
  components: {
    CloseButton
  },
}
</script>

<style scoped lang="scss">
#static-next, #static-previous {
  cursor: none;
}
</style>