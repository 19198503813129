<template>
  <div class="page-content">
    <!-- Disclaimer Section -->
    <section id="disclaimer-section" class="page-section text-white mb-0">
      <div class="container align-items-center">

        <!-- Disclaimer Heading -->
        <div class="text-center mt-3 mb-3">
          <h1 class="page-section-heading text-uppercase text-secondary pb-lg-3">Disclaimer</h1>
        </div>

        <!-- Disclaimer Section Content -->
        <div class="row">
          <div class="col-lg-8 mx-auto">
            <p class="text-primary">De informatie op deze website van de Erfgoedcollectie wordt met de grootst mogelijke
              zorg samengesteld. Desondanks kan het voorkomen dat er onvolkomenheden in de getoonde informatie zitten. We
              stellen het op prijs als wij hierop geattendeerd worden via erfgoed@tilburguniversity.edu.
            </p>
            <p class="text-primary">De Erfgoedcollectie kan echter niet aansprakelijk worden gesteld voor de inhoud van de informatie en/of voor
              de gevolgen van het gebruik ervan. Aan de gepresenteerde gegevens kunnen geen rechten worden ontleend.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Disclaimer close button -->
    <CloseButton/>

  </div>
</template>

<script>
import CloseButton from '@/components/CloseButton'

export default {
  name: 'Disclaimer',
  components: {
    CloseButton
  },
}
</script>