<template>
  <div class="page-content">
    <!-- Copyright Section -->
    <section id="copyright-section" class="page-section text-white mb-0">
      <div class="container align-items-center">

        <!-- Copyright Heading -->
        <div class="text-center mt-3 mb-3">
          <h1 class="page-section-heading text-uppercase text-secondary pb-lg-3">Copyright</h1>
        </div>

        <!-- Copyright Section Content -->
        <div class="row">
          <div class="col-lg-8 mx-auto">
            <p class="text-primary">De Erfgoedcollectie heeft de auteursrechten van de getoonde items of anderzijds
              geprobeerd de rechthebbenden te achterhalen. Voor zover personen menen auteursrechtelijk aanspraken te
              hebben,
              kunnen zij contact opnemen via <a href="mailto:erfgoed@tilburguniversity.edu" class="text-secondary">erfgoed@tilburguniversity.edu</a>.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Copyright close button -->
    <CloseButton/>

  </div>
</template>

<script>
import CloseButton from '@/components/CloseButton'

export default {
  name: 'Copyright',
  components: {
    CloseButton
  },
}
</script>