<template>
  <div>
    <div class="container" id="diw-container">
      <div id="diw-search"><!-- autogen form here --></div>
      <div id="diw-advanced-search"><!-- autogen advanced search here --></div>
      <div id="diw-geo-search"><!-- autogen geo search here --></div>
      <div id="diw-query-info"><!-- autogen query info here --></div>
      <div id="diw-breadcrumbs"><!-- autogen breadcrumbs here --></div>
      <div class="diw-pagination"><!-- autogen top pagination here --></div>
      <div id="diw-results-container" class="row">
        <div id="diw-results" class="col-md-8"><!-- autogen search results here --></div>
        <div id="diw-facets" class="col-md-4"><!-- autogen facets here --></div>
      </div>
      <div id="diw-item-container">
        <div id="diw-item-media"><!-- autogen item image/deepzoom here --></div>
        <div id="diw-item-data"><!-- autogen item metadatafields here --></div>
        <div id="diw-item-related"><!-- autogen related items here --></div>
      </div>
      <div class="diw-pagination bottom"><!-- autogen bottom pagination here --></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InstantWebsite',
  methods: {
    writeScripts() {
      document.open();
      document.write('<script id="diw-dependencies" type="text/javascript" src="js/diw-dependencies.min.js"></' + 'script>')
      document.write('<script id="diw-core" type="text/javascript" src="js/diw-core.js"></' + 'script>')
      document.write('<script id="diw-config" type="text/javascript" src="js/diw-config.js"></' + 'script>')
      document.close();
    }
  },
  created() {
    this.writeScripts()
  }
}
</script>

<style lang="scss">
@import "../scss/components/instant-website";
</style>
