<template>
  <div class="page-content">
    <!-- Privacy Section -->
    <section id="privacy-section" class="page-section text-white mb-0">
      <div class="container align-items-center">

        <!-- Privacy Heading -->
        <div class="text-center mt-3 mb-3">
          <h1 class="page-section-heading text-uppercase text-secondary pb-lg-3">Privacy</h1>
        </div>

        <!-- Privacy Section Content -->
        <div class="row">
          <div class="col-lg-8 mx-auto">
            <p class="text-primary">Om vragen en reacties te kunnen verwerken heeft de Erfgoedcollectie enige persoonlijke
              gegevens nodig zoals naam en e-mailadres. Deze persoonlijke gegevens zullen zorgvuldig en vertrouwelijk worden
              behandeld.
            </p>
            <p class="text-primary">De Erfgoedcollectie is onderdeel van Tilburg University en volgt het privacy-beleid van deze instelling.
              Nadere informatie hieromtrent vindt u op de pagina
              <a
                  href="https://www.tilburguniversity.edu/nl/privacy"
                  target="_blank"
                  class="text-secondary">Privacy Statement Tilburg University
              </a>.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Privacy close button -->
    <CloseButton/>

  </div>
</template>

<script>
import CloseButton from '@/components/CloseButton'

export default {
  name: 'Privacy',
  components: {
    CloseButton
  },
}
</script>