<template>
  <div class="scroll-to-top position-fixed"
       :class="{ 'show': !showScrollTopButton }">
    <a class="d-block text-center text-white rounded" href="#app">
      <i class="fa fa-chevron-up"></i>
    </a>
  </div>
</template>

<script>

export default {
  name: 'Scroll-to-top-button',
  data() {
    return {
      showScrollTopButton: true,
      lastScrollPosition: 0,
    }
  },
  methods: {
    onScroll() {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      if (currentScrollPosition < 0) {
        return
      }
      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 160) {
        return
      }
      this.showScrollTopButton = currentScrollPosition < this.lastScrollPosition
      this.lastScrollPosition = currentScrollPosition
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>

<style lang="scss">
@import '../scss/base/buttons';

</style>
