import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import $ from 'jquery'
import jQuery from 'jquery'

Vue.config.productionTip = false

new Vue({
  router,
  $,
  jQuery,
  render: h => h(App),
}).$mount('#app')
