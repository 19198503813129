<template>
  <div class="page-content">
    <!-- Cookies Section -->
    <section id="cookies-section" class="page-section text-white mb-0">
      <div class="container align-items-center">

        <!-- Cookies Heading -->
        <div class="text-center mt-3 mb-3">
          <h1 class="page-section-heading text-uppercase text-secondary pb-lg-3">Cookies</h1>
        </div>

        <!-- Cookies Section Content -->
        <div class="row">
          <div class="col-lg-8 mx-auto">
            <p class="text-primary">Deze website maakt gebruik van functionele en analytische “cookies” (tekstbestandjes
              die
              op de computer worden geplaatst) om de website te analyseren en verbeteren. Voor meer informatie zie
              <a href="https://www.tilburguniversity.edu/nl/disclaimer/cookies" target="_blank" class="text-secondary">cookies</a>.
            </p>
            <p class="text-primary">Op deze website zijn knoppen opgenomen om pagina's te kunnen promoten of delen op de
              sociale netwerken
              Facebook, Twitter etc. Deze Social Media-knoppen werken met cookies van derde partijen. Deze cookies
              worden
              echter alleen geplaatst wanneer op deze links geklikt wordt.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Cookies close button -->
    <CloseButton/>

  </div>
</template>

<script>
import CloseButton from '@/components/CloseButton'

export default {
  name: 'Cookies',
  components: {
    CloseButton
  },
}
</script>