<template>
  <div id="app">
    <NavBar/>
    <router-view/>
    <ScrollToTopButton/>
    <Footer/>
  </div>
</template>

<script>
import NavBar from './components/NavBar'
import Footer from './components/Footer'
import ScrollToTopButton from './components/ScrollToTopButton'


export default {
  name: 'App',
  components: {
    ScrollToTopButton,
    NavBar,
    Footer,
  },
}
</script>

<style lang="scss">
@import './scss/base/buttons';
@import './scss/base/page';

html {
  scroll-behavior: smooth;
}

.page-content {
  padding-top: 160px;
  @media only screen and (max-width: 991.98px) {
    padding-top: 120px;
  }
}
</style>
