import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home'
import Help from '@/views/Help'
import Disclaimer from '@/views/Disclaimer'
import Copyright from '@/views/Copyright'
import Privacy from '@/views/Privacy'
import Cookies from '@/views/Cookies'

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/help',
            name: 'Help',
            component: Help
        },
        {
            path: '/disclaimer',
            name: 'Disclaimer',
            component: Disclaimer
        },
        {
            path: '/copyright',
            name: 'Copyright',
            component: Copyright
        },
        {
            path: '/privacy',
            name: 'Privacy',
            component: Privacy
        },
        {
            path: '/cookies',
            name: 'Cookies',
            component: Cookies
        }
    ]
})