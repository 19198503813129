<template>
  <div>
    <CookieAlert/>
    <Loader v-show="loaderVisible"/>
    <InstantWebsite v-show="iwVisible" class="page-content"/>
  </div>
</template>

<script>
import InstantWebsite from '../components/InstantWebsite'
import CookieAlert from '../components/CookieAlert'
import Loader from '../components/Loader'

export default {
  name: 'Home',
  components: {
    Loader,
    CookieAlert,
    InstantWebsite,
  },
  data() {
    return {
      loaderVisible: true,
      iwVisible: false
    }
  },
  created() {
    setTimeout(() => {
      this.loaderVisible = false
    }, 1000)
  },
  mounted() {
    setTimeout(() => {
      this.iwVisible = true
    }, 1000)
  }
}
</script>