<template>
  <div>
    <!-- Footer -->
    <div class="container">
      <footer id="footer" class="footer text-center collapse fixed-bottom">
        <div class="row no-gutters">
          <!-- Footer Location -->
          <div class="col-lg-4 mb-5 mb-lg-0 h-100">
            <h4 class="mb-4">Contact</h4>
            <div class="row justify-content-center">
              <p class="lead mb-0 ml-2">Warandelaan 2 | 5027 AB Tilburg
                <br>013-4662681
                <br><a href="mailto:erfgoed@tilburguniversity.edu" class="text-white">erfgoed@tilburguniversity.edu</a>
              </p>
            </div>
          </div>

          <!-- Footer Social Media -->
          <div class="col-lg-4 mb-5 mb-lg-0">
            <!-- -->
          </div>

          <!-- Footer Accountability Text -->
          <div class="col-lg-4 mb-5 mb-lg-0">
            <h4 class="mb-4">Verantwoording</h4>
            <div class="row justify-content-center">
              <p class="lead align-right mb-0 ml-2"><a href="/disclaimer" target="_blank">Disclaimer</a>
                <br><a href="/copyright" target="_blank">Copyright</a>
                <br><a href="/privacy" target="_blank">Privacy</a>
                <br><a href="/cookies" target="_blank">Cookies</a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>

    <!-- Collapsed Footer -->
    <div id="footer-button-collapse"
         class="collapsed footer-collapsed"
         :class="{ 'element-hide-bottom': isMobile && scrollDown, 'element-show-bottom': isMobile && scrollUp }"
         data-toggle="collapse"
         data-target="#footer">
      <div class="text-center">
        Contact en informatie<i class="fa fa-chevron-down"></i>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Footer',
  data() {
    return {
      isMobile: false,
      scrollUp: false,
      scrollDown: false,
      lastScrollPosition: 0,
      fullYear: new Date().getFullYear()
    }
  },
  methods: {
    onScroll() {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      if (currentScrollPosition < 0) {
        return
      }

      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) === 0) {
        return
      }

      this.scrollDown = currentScrollPosition > this.lastScrollPosition
      this.scrollUp = currentScrollPosition < this.lastScrollPosition

      let w = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
      this.isMobile = w < 576;
      this.lastScrollPosition = currentScrollPosition
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>

<style lang="scss">
@import "../scss/base/annimations";
@import "../scss/components/footer";
</style>
