<template>
  <!-- Close button -->
  <div class="text-center mb-5">
    <a @click=windowClose class="btn btn-xl btn-outline-secondary my-3 py-3">
      <i class="fas fa-times mr-2"></i>
      Sluit deze pagina
    </a>
  </div>
</template>

<script>
export default {
  name: "CloseButton",
  methods: {
    windowClose() {
      window.open('', '_parent', '')
      window.close()
    }
  }
}
</script>

<style scoped lang="scss">
.btn-xl {
  padding: 1rem 1.75rem !important;
  font-size: 1.25rem !important;
}

</style>